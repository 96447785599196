/*  https://github.com/kristoferjoseph/flexboxgrid */
@import '~flexboxgrid/dist/flexboxgrid.css';

.App {
  text-align: center;
}

.row {
  margin: 0;
}

a, a:hover {
  text-decoration: none;
}

.title-section {
  margin-bottom: 0;
}

.category_row, .organization_row, .starting_point_row, .box--simple {
  /* border: 2px solids red; */
  transition: ease-in 0.06s; 
}

.category_row:hover, .organization_row:hover, .starting_point_row:hover, .box--simple:hover {
  background-color: #1e6194;
  /* transform: scale(1.005); */
}

.category_row:active, .organization_row:active, .starting_point_row:active, .box--simple:active {
  background-color: #e76423;
  /*transform: scale(0.98); */
}


.heading-1 {
  margin-bottom: 0.5rem;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.box {
  position: relative;
  box-sizing: border-box;
  min-height: 1rem;
  /*margin-bottom: 0; */
  /* background: #69ade0 ; */
  /* border: 1px solid #FFF; */
  /* border-radius: 2px; */
  overflow: hidden;
  text-align: center;
  color: #000000;
}

.box:before {
  content:'';
  float:left;
  padding-top:100%;
}

.box--short {
  position: relative;
  box-sizing: border-box;
  min-height: 1rem;
  /*margin-bottom: 0; */
  background: #277dbe ;
  /* border: 1px solid #FFF; */
  /* border-radius: 2px; */
  overflow: hidden;
  text-align: center;
  color: #fff;
}

.box--simple {
  position: relative;
  box-sizing: border-box;
  min-height: 1rem;
  /*margin-bottom: 0; */
  background: #277dbe ;
  /* border: 1px solid #FFF; */
  /* border-radius: 2px; */
  overflow: hidden;
  text-align: center;
  color: #fff;
  padding: 0.5em;
  cursor: pointer;
}
.box--simple.selected {
  background: #e76423 ;
}

.box--short:before {
  content:'';
  float:left;
  padding-top:2rem;
}

.box-container {
  padding: 0.5rem;
}

.flag-button {
  border: none;
  background-color: transparent;
  height: 4rem;
  width: 4rem;
  margin: 0;
  padding: 0;
}

@media only screen and (min-width: 325px) {
  .box-container {
    padding: 1rem;
  }
}

@media only screen and (min-width: 600px) {
  .box-container {
    padding: 1.5rem;
  }
}

@media only screen and (min-width: 1700px) {
  .box-container {
    padding: 2rem;
  }
}

.row-padding-deprecated {
  padding-left: .5rem;
  padding-right: .5rem;
  padding-bottom: .5rem;
  padding-top: .5rem;

}

@media only screen and (min-width: 350px) {
  .row-padding-deprecated {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
}

@media only screen and (min-width: 700px) {
  .row-padding-deprecated {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
  }
}

@media only screen and (min-width: 800px) {
  .row-padding-deprecated {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-top: 2rem;

  }
}

.row-padding--small {
  padding-left: .5rem;
  padding-right: .5rem;
  padding-bottom: .1rem;
  padding-top: .1rem;
}

@media only screen and (min-width: 800px) {
  .row-padding--small {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: .2rem;
    padding-top: .2rem;
  }
}

.row-padding--extra-small {
  padding-left: .5rem;
  padding-right: .5rem;
  padding-bottom: .3rem;
  padding-top: .3rem;
}

@media only screen and (min-width: 800px) {
  .row-padding--extra-small {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: .2rem;
    padding-top: .2rem;
  }
}

.margin-top-none{
  margin-top: 0;
}

.stack-none {
  margin-bottom: 0;
}

.stack-s {
  margin-bottom: 1rem;
}

.stack-m {
  margin-bottom: 2rem;
}

.stack-l {
  margin-bottom: 4rem;
}

.s-inline-s {
  margin-right: 4px;
}

.s-inline-m {
  margin-right: 8px;
}

.s-inline-l {
  margin-right: 16px;
}

.s-inset-s {
  padding: 4px;
}

.s-inset-left-s {
  padding-left: 4px; 
}


.s-inset-m {
  padding: 8px;
}

.s-inset-left-m {
  padding-left: 8px; 
}

.s-inset-l {
  padding: 16px;
}

.s-inset-left-l {
  padding-left: 16px; 
}

.s-stack-s {
  margin-bottom: 4px;
}

.s-stack-m {
  margin-bottom: 16px;
}

.s-stack-l {
  margin-bottom: 32px;
}

.fill-white{
  fill: #ffffff;
}

.footer_button_row {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  bottom: 0;
}

.footer_nav.footer_button_row {
  position: fixed;
}

.width-100{
  width: 100%;
}

.button.button--primary {
  background-color: #277dbe ;
}
.tag {
  border: 2px solid #277dbe ;
  border-radius: 2rem;
  margin-right: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  padding-right: 0;
}

.tag-counter {
  background-color:#EEFFFF;
  border-radius: 2rem;
  font-size: 0.9rem;
  line-height:1rem;
  padding: 4px;
  margin-right: 0.1rem;
  display:inline-block;
  width: 1rem;
  height: 1rem;
}

.tag--toggled {
  background-color:#277dbe ;
  color:white;
}

.tag--toggled .tag-counter {
  background-color:#006EEE;
}

.sidebarStyle {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 4px;
  background-color: #404040;
  color: #ffffff;
  z-index: 1 !important;
  padding: 2px;
  font-weight: bold;
  font-size: 8px;
}


.line {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: #4b9cdb;
}

@keyframes loadingC {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, 0);
  }
}


.load-3 .line:nth-last-child(1) {
  animation: loadingC 0.6s 0.1s linear infinite;
}
.load-3 .line:nth-last-child(2) {
  animation: loadingC 0.6s 0.2s linear infinite;
}
.load-3 .line:nth-last-child(3) {
  animation: loadingC 0.6s 0.3s linear infinite;
}

